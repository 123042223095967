import React, { createContext, useContext, useState, useReducer, useEffect } from 'react'

import { ModalPortal } from 'components/modal'

const ModalContext = createContext()

export const ProvideModal = ( { children, data } ) => {
	const dataWrapper = useProvideModal()

	return (
		<ModalContext.Provider value={ dataWrapper }>
			{children}
			<ModalPortal/>
		</ModalContext.Provider>
	)
}

// Hook for child components to get the Modal object ...
// ... and re-render when it changes.
export const useModal = () => {
	return useContext(ModalContext)
}

// Provider hook that creates Data object and handles state
const useProvideModal = () => {

	const reducer = (state, action) => {
		switch (action.type) {
			case 'increment':
				return {count: state.count + 1}
			case 'decrement':
				return {count: state.count - 1}
			default:
				throw new Error()
		}
	}

	const [portal, setPortal] = useState( 0 )
	const [state, dispatch] = useReducer( reducer, {count: 0} )

	const add = () => {
		dispatch( { type: 'increment' } )
	}

	const remove = () => {
		dispatch( { type: 'decrement' } )
	}

	return {
		portal,
		setPortal,
		count: state.count,
		add,
		remove
	}
}
