import * as Sentry from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";
import * as utils from "utils";

if (process.env.SENTRY_ON == "true") {
	Sentry.init({
		dsn: `${process.env.SENTRY_DSN}`,
		integrations: [
			new CaptureConsole({
				levels: ["error"],
			}),
		],
		maxBreadcrumbs: 50,
	});
}

// General config
Sentry.configureScope(function (scope) {
	scope.setTag("nodejs", process.version);
	scope.setTag("runtimeEngine", utils.isServerSide() ? "Server" : "Browser");
	scope.setTag("Environement", process.env.APP_ENV);
});

/**
 * Provide general data to sentry report
 * @param {*} data - Data to fill custom sentry config
 */
export const provideGeneralData = (data) => {
	Sentry.configureScope(function (scope) {
		scope.setExtra("user", data.user);
		scope.setExtra("cartToken", data.cart.token);
		scope.setExtra("cartContent", data.cart.cart);
		scope.setExtra("checkout", data.checkout);
	});
};

/**
 * Setup new data to report
 * @param {*} dataName - label for custom sentry field
 * @param {*} dataToReport - Data for custom sentry field
 */
export const provideSpecificData = (dataName, dataToReport) => {
	Sentry.configureScope(function (scope) {
		scope.setExtra(dataName, dataToReport);
	});
};

export default Sentry;
