import React from "react";
import { useRouter } from "next/router";

import Logo from "components/logo";
import { Navbar, Menu } from "components/navigation";

import { useUser } from "contexts/UserContext";
import { useCart } from "contexts/CartContext";
import { CSS_BREAKPOINT_SEPARATOR } from "libs/variables";

export default function (props) {
	const user = useUser();
	const cart = useCart();
	const router = useRouter();

	const { isUserLoggedIn } = user;

	const { getCartQuantity, toggle, isOpen } = cart;

	const cartQuantity = getCartQuantity();

	return (
		<Navbar 
			id="site-header" 
			fixed="top" 
			background="white" 
			className={`u-height u-height-xhuge${CSS_BREAKPOINT_SEPARATOR}tablet`}
			zIndex={99}
		>
			<Menu
				hasLayout
				isPrimary
				widths={{
					tablet: "1/5",
				}}
			>
				<Logo 
					isColorised 
					baseline={false} 
					className={`u-height u-height-xhuge${CSS_BREAKPOINT_SEPARATOR}tablet`} 
				/>
			</Menu>
			<Menu
				hasMobileSupport
				hasLayout
				widths={{
					tablet: "3/5",
				}}
				align="center"
				type="primary"
				items={[
					{
						title: "Séjours surprises",
						href: "/[taxon]",
						as: "/sejours-surprises",
					},
					{
						title: "Cadeaux",
						href: "/[taxon]",
						as: "/cadeaux",
						isSelected: router.query && router.query.taxon === "cadeaux",
					},
					{
						title: "Événements",
						href: "/[taxon]",
						as: "/evenements",
						isSelected: router.query && router.query.taxon === "evenements",
					},
					{
						title: "Création de jeux",
						href: "/[taxon]",
						as: "/creation-de-jeux",
						isSelected: router.query && router.query.taxon === "creation-de-jeux",
					},
					{
						title: "Team Building",
						href: "/[taxon]",
						as: "/team-building",
						isSelected: router.query && router.query.taxon === "team-building",
					},
					{
						title: "Contact",
						href: "/contact",
					},
				]}
			/>
			<Menu
				hasLayout
				widths={{
					tablet: "1/5",
				}}
				align="end"
				type="primary"
				color="primary"
				items={[
					{
						icon: "person",
						title: isUserLoggedIn ? "Compte" : "Connexion",
						href: "/compte",
						isSelected: router.asPath.substring(0, 7) === "/compte",
					},
					{
						icon: isOpen ? "close" : "cart",
						title: "Panier",
						onClick: toggle,
						isSelected: isOpen,
						badge: {
							label: "Nombre de produit(s)",
							value: cartQuantity > 0 ? cartQuantity : null,
							color: "primary",
						},
					},
				]}
			/>
		</Navbar>
	);
}
