
import classNames from 'classnames'
import { useState, useEffect, useRef, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'

import { Icon } from 'components/media'
import { isServerSide, isFunction } from 'libs/utils'

import { useModal } from 'contexts/ModalContext'

export default function Modal( { children, ...props } ) {

	// const {
	// 	portal
	// } = useModal()

	if( isServerSide() )
		return null

	return createPortal(
		<ModalView { ...props }>{ children }</ModalView>,
		document.body
	)
}

export function ModalView( props ) {

	const modalCtx = useModal()

	const {
		active,
		size,
		onClose,
		hasOverlay,
		disableKeyEvents,
		children
	} = props

	// Enable keyboard events
	useEffect(
		() => {
			if( ! disableKeyEvents && isFunction( onClose ) ) {
				const handleEvent = e => {
					if( e.key === 'Escape' ) {
						document.removeEventListener('keyup', handleEvent, false )
						onClose()
					}
				}
				document.addEventListener('keyup', handleEvent, false)

				return () => {
					document.removeEventListener('keyup', handleEvent, false )
				}
			}
		},
		[]
	)

	return (
		<CSSTransition
			in={ active }
			timeout={ 300 }
			unmountOnExit
			onEntered={ () => modalCtx.add() }
			onExit={ () => modalCtx.remove() }
		>
			{ state => (
				<div
					className={ classNames(
						'o-modal',
						{
							[`o-modal--${size}`]: size,
							'o-modal--overlay': hasOverlay,
							'o-modal--active': state === 'entered',
							'o-modal--close': isFunction( onClose )
						}
					) }
				>
					{ hasOverlay && (
						<div
							className={ classNames(
								'o-modal__overlay',
								'o-modal__overlay--clickable'
							) }
							onClick={ onClose }
							title="Cliquez pour fermer"
						/>
					) }
					<div
						className="o-modal__container"
					>
						{ children }
					</div>
					{ isFunction( onClose ) && (
						<div
							className="o-modal__close"
						>
							<Icon
								name="close"
								size="large"
								onClick={ onClose }
								title="Fermer"
							/>
						</div>
					) }
				</div>
			) }
		</CSSTransition>
	)
}

export function ModalPortal( props ) {

	const {
		setPortal
	} = useModal()

	const portalRef = useRef( null )

	useEffect(
		() => {
			if( portalRef.current ) {
				setPortal( portalRef.current )
			}
		},
		[portalRef]
	)

	return (
		<aside ref={ portalRef } className="o-modal__portal"></aside>
	)
}
