
import { useState, useEffect, useRef } from 'react'
import { Formik, Form, Field } from 'formik'
import { useAlert } from 'react-alert'

import MarkDown from 'components/markdown'
import { Label } from 'components/form/elements'
import { InputField } from 'components/form/fields'
import Button from 'components/button'

import userClient from 'clients/user-client'

import { objectValidationSchema, emailValidationSchema } from 'libs/form-validation'
import { isObject, parseError, responseCallback } from 'libs/utils'

export default function NewsletterForm( props ) {

	const alert = useAlert()

	const {
		className
	} = props

	const handleSubmit = async (values, { setSubmitting, resetForm }) => {
		const response = await userClient.subscribeNewsletter( values.email )

		responseCallback(
			response,
			response => {
				resetForm()
				alert.success( 'Vous êtes maintenant abonné.e à notre newsletter. Merci.' )
			},
			error => {
				resetForm()
				alert.error( <MarkDown>{ parseError( error, '  \n' ) }</MarkDown> )
			}
		)
	}

	return (
		<Formik
			initialValues={ {
				email: ''
			} }
			validationSchema={ objectValidationSchema( {
				email: emailValidationSchema
			} ) }
			onSubmit={ handleSubmit }
		>
			{ () => (
				<Form
					className={ className }
				>
					<Label>
						Abonnez-vous à notre newsletter
					</Label>
					<Field
						component={ InputField }
						name="email"
						placeholder="Votre email"
						color="white"
						tabIndex="-1"
						after={
							<Button id="newsletter-subscribe">Abonnez-vous</Button>
						}
					/>
				</Form>
			) }
		</Formik>
	)
}
