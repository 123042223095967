import classNames from "classnames";
import React, { useRef, useEffect, useState } from "react";
import { useRouter } from "next/router";

import Header from "./header";
import Footer from "./footer";
import Loader from "components/loader";
import Page from "components/page";
import Cart from "components/cart";

import { useMenu } from "contexts/MenuContext";
import { useModal } from "contexts/ModalContext";
import { useCart } from "contexts/CartContext";

import { scrollToTop } from "libs/navigation";
import { isNull, isServerSide } from "libs/utils";

export default function Component(props) {
	const menuCtx = useMenu();
	const modalCtx = useModal();
	const cartCtx = useCart();
	const router = useRouter();

	const pageRef = useRef();

	/**
	 * Loader
	 */

	const [loading, setLoading] = useState(true);
	const handleRouteChangeStart = () => setLoading(true);
	const handleRouteChangeComplete = () => setLoading(false);

	useEffect(() => {
		if (loading) {
			// Close mobile menu
			menuCtx.setIsOpen(false);
			document.firstElementChild.style.scrollBehavior = 'auto'
		} else {
			// Reset page scroll
			scrollToTop(false);
			document.firstElementChild.style.scrollBehavior = null
		}
	}, [loading]);

	// Init loader events
	useEffect(() => {
		router.events.on("routeChangeStart", handleRouteChangeStart);
		router.events.on("routeChangeComplete", handleRouteChangeComplete);
		handleRouteChangeComplete();

		return () => {
			router.events.off("routeChangeStart", handleRouteChangeStart);
			router.events.off("routeChangeComplete", handleRouteChangeComplete);
		};
	}, []);

	/**
	 * Scroll nav
	 */

	const [scrollDir, setScrollDir] = useState(0);

	useEffect(() => {
		let scrollTimer = 0;
		let scrollPos = isServerSide() ? 0 : window.scrollY;
		let isScrolling = false;

		const scrollNav = (e) => {
			if (!isScrolling) {
				setScrollDir(window.scrollY >= scrollPos ? 1 : -1);
				isScrolling = true;
			}

			clearTimeout(scrollTimer);
			scrollTimer = setTimeout(() => {
				scrollPos = window.scrollY;
				isScrolling = false;
			}, 50);
		};

		window.addEventListener("scroll", scrollNav);

		return () => window.removeEventListener("scroll", scrollNav);
	}, []);

	/**
	 * Add resize event to window when opening cart
	 */

	const handleResizeTransitionend = () => {

		// Modern browsers
		if( typeof (Event) === 'function' ) {
			window.dispatchEvent( new Event( 'resize' ) )
		
		// Old browsers and especially IE
		} else {
			const resizeEvent = window.document.createEvent( 'UIEvents' )
			resizeEvent.initUIEvent( 'resize', true, false, window, 0 )
			window.dispatchEvent( resizeEvent )
		}

		pageRef.current.removeEventListener(
			"transitionend",
			handleResizeTransitionend,
			false
		);
	};

	const triggerResize = () => {
		pageRef.current.addEventListener(
			"transitionend",
			handleResizeTransitionend,
			false
		);
	};

	useEffect(() => {
		triggerResize();
	}, [cartCtx.isOpen]);

	/**
	 * Body classes
	 */

	const setBodyClass = () => {
		let pageClass = "home";
		let checkoutClass = null;
		let cartClass = null;
		let modalClass = null;
		let menuClass = null;
		let loadingClass = null;
		let scrollClass = null;

		if (router.asPath !== "/") {
			const pathArray = router.asPath.split("/").filter((el) => el !== "");

			pageClass = pathArray.map(
				(path, index) => `page--${pathArray.slice(0, index + 1).join("-")}`
			);

			checkoutClass =
				pathArray[0] === "commande" &&
				pathArray[1] !== "merci" &&
				!cartCtx.isCartEmpty
					? ["sidebar", "checkout"]
					: null;
		}

		// Cart
		if (menuCtx.isOpen) {
			menuClass = "menu--visible";
		}

		// Modal
		if (modalCtx.count > 0) {
			modalClass = "modal--active";
		}

		// Cart
		if (cartCtx.isOpen) {
			cartClass = "cart--visible";
		}

		// Loading
		if (loading) {
			loadingClass = "loading";
		}

		// Loading
		if (scrollDir > 0) {
			scrollClass = "scrolled";
		}

		document.documentElement.className = classNames(
			pageClass,
			checkoutClass,
			cartClass,
			modalClass,
			menuClass,
			loadingClass,
			scrollClass
		);
	};

	useEffect(setBodyClass, [
		router.pathname,
		menuCtx.isOpen,
		cartCtx.isOpen,
		modalCtx.count,
		loading,
		scrollDir,
	]);

	const { children } = props;

	return (
		<div className="app">
			<Loader isPrimary />
			<Header />
			<Page ref={pageRef}>
				{children}
				<Footer />
			</Page>
			<Cart {...cartCtx} />
		</div>
	);
}
