
import classNames from 'classnames'
import Link from 'next/link'

export default function Logo( props ) {

	const {
		isColorised,
		color,
		size,
		baseline = true,
		className
	} = props;

	return (
		<Link href="/">
			<a
				className="c-logo"
			>
				<svg
					className={ classNames(
						'o-svg',
						{
							[`o-svg--color-${color}`]: color && ! isColorised,
							[`u-height-${size}`]: size
						},
						className
					) }
					preserveAspectRatio="none"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 279.33 229.73"
				>
					<g className={ classNames( { 'o-svg--color-logo': isColorised && ! color } ) }>
						<path d="M139.61,114.33c-1.51,0-2.88-0.69-4.38-1.29L109,102.41c-2.77-1.32-4.37-4.91-4.37-8.33V60.8
							c0-3.42,1.57-7.04,4.37-8.33l26.24-10.49c3.01-1.42,5.39-1.49,8.75,0l26.24,10.49c2.88,1.22,4.37,4.9,4.37,8.33v33.29
							c0,3.42-1.63,7.15-4.37,8.33l-26.24,10.63C142.46,113.62,141.12,114.33,139.61,114.33z M139.61,41.01c-0.5,0-0.99,0.14-1.43,0.42
							l-26.24,16.65c-0.88,0.56-1.43,1.61-1.43,2.73v33.29c0,1.12,0.55,2.17,1.43,2.73l26.24,16.64c0.89,0.56,1.98,0.56,2.87,0
							l26.24-16.64c0.88-0.56,1.43-1.61,1.43-2.73V60.8c0-1.12-0.55-2.17-1.43-2.73l-26.24-16.64C140.6,41.15,140.1,41.01,139.61,41.01z"
							/>
						<g>
							<g>
								<path d="M60.05,147.02c-1.46-0.79-2.9-1.42-4.32-1.86c-1.42-0.45-2.7-0.67-3.82-0.67c-0.89,0-1.63,0.17-2.21,0.5
									c-0.58,0.33-0.87,0.86-0.87,1.59c0,0.7,0.34,1.25,1.02,1.66c0.68,0.41,1.56,0.77,2.63,1.07c1.08,0.3,2.21,0.64,3.4,1.02
									s2.33,0.87,3.4,1.47c1.07,0.6,1.94,1.4,2.61,2.41c0.66,1.01,0.99,2.31,0.99,3.9c0,1.79-0.48,3.3-1.44,4.55
									c-0.96,1.24-2.27,2.17-3.92,2.78c-1.66,0.61-3.49,0.92-5.51,0.92c-2.15,0-4.24-0.31-6.26-0.94c-2.02-0.63-3.79-1.56-5.31-2.78
									l2.48-4.97c1.39,1.06,2.92,1.88,4.59,2.46c1.67,0.58,3.24,0.87,4.69,0.87c0.66,0,1.24-0.08,1.74-0.25
									c0.5-0.17,0.89-0.41,1.19-0.75c0.3-0.33,0.45-0.75,0.45-1.24c0-0.73-0.33-1.32-0.99-1.76c-0.66-0.45-1.52-0.83-2.58-1.14
									c-1.06-0.31-2.19-0.65-3.38-0.99c-1.19-0.35-2.31-0.82-3.35-1.42c-1.04-0.6-1.9-1.38-2.56-2.36s-0.99-2.24-0.99-3.8
									c0-1.85,0.48-3.39,1.44-4.59c0.96-1.21,2.24-2.11,3.82-2.71c1.59-0.6,3.34-0.89,5.27-0.89c1.85,0,3.68,0.25,5.49,0.75
									s3.45,1.21,4.94,2.14L60.05,147.02z"/>
								<path d="M70.58,127.6c1.22,0,2.23,0.4,3,1.19c0.78,0.79,1.17,1.79,1.17,2.98c0,1.19-0.39,2.19-1.17,2.98
									c-0.78,0.8-1.78,1.19-3,1.19c-1.16,0-2.14-0.4-2.93-1.19c-0.8-0.79-1.19-1.79-1.19-2.98c0-1.19,0.4-2.19,1.19-2.98
									C68.44,128,69.42,127.6,70.58,127.6z M66.75,139.37h7.65v26.67h-7.65V139.37z"/>
								<path d="M80.76,129.19h7.65v36.86h-7.65V129.19z M96.85,139.12c2.52,0,4.71,0.57,6.58,1.71
									c1.87,1.14,3.34,2.74,4.4,4.79c1.06,2.05,1.59,4.45,1.59,7.2c0,2.68-0.51,5.03-1.54,7.05c-1.03,2.02-2.47,3.59-4.32,4.72
									c-1.85,1.13-4.04,1.69-6.56,1.69c-2.32,0-4.31-0.54-5.96-1.61c-1.66-1.08-2.93-2.63-3.82-4.67c-0.89-2.04-1.34-4.43-1.34-7.18
									c0-2.85,0.44-5.3,1.32-7.35c0.88-2.05,2.14-3.63,3.77-4.72C92.61,139.67,94.57,139.12,96.85,139.12z M95.06,145.09
									c-1.29,0-2.44,0.32-3.45,0.97c-1.01,0.65-1.8,1.53-2.36,2.66c-0.56,1.13-0.84,2.43-0.84,3.92s0.28,2.81,0.84,3.95
									c0.56,1.14,1.35,2.03,2.36,2.66c1.01,0.63,2.16,0.94,3.45,0.94c1.32,0,2.48-0.31,3.48-0.94c0.99-0.63,1.76-1.51,2.31-2.66
									c0.55-1.14,0.82-2.46,0.82-3.95c0-1.46-0.27-2.76-0.82-3.9c-0.55-1.14-1.32-2.04-2.31-2.68
									C97.55,145.41,96.39,145.09,95.06,145.09z"/>
								<path d="M125.31,169.13c-0.96,2.28-2.25,4.01-3.88,5.17c-1.62,1.16-3.59,1.74-5.91,1.74c-1.39,0-2.67-0.21-3.85-0.62
									c-1.17-0.41-2.31-1.05-3.4-1.91l3.23-5.31c1.16,0.93,2.32,1.39,3.48,1.39c0.79,0,1.49-0.21,2.09-0.62
									c0.6-0.41,1.08-1.04,1.44-1.86l0.74-1.59l-11.08-26.13h7.9l7,18.68l6.31-18.68h7.65L125.31,169.13z"/>
								<path d="M139.37,129.19h7.65v36.86h-7.65V129.19z"/>
								<path d="M153.68,129.19h7.65v36.86h-7.65V129.19z"/>
								<path d="M171.56,127.6c1.22,0,2.23,0.4,3,1.19c0.78,0.79,1.17,1.79,1.17,2.98c0,1.19-0.39,2.19-1.17,2.98
									c-0.78,0.8-1.78,1.19-3,1.19c-1.16,0-2.14-0.4-2.93-1.19c-0.8-0.79-1.19-1.79-1.19-2.98c0-1.19,0.4-2.19,1.19-2.98
									C169.42,128,170.4,127.6,171.56,127.6z M167.73,139.37h7.65v26.67h-7.65V139.37z"/>
								<path d="M181.49,139.37h7.65v26.67h-7.65V139.37z M200.51,151.44c0-1.75-0.47-3.13-1.42-4.12
									c-0.94-0.99-2.23-1.49-3.85-1.49c-1.89,0.03-3.38,0.71-4.47,2.04c-1.09,1.33-1.64,3-1.64,5.02h-1.94c0-3.11,0.45-5.69,1.37-7.72
									s2.2-3.56,3.88-4.57c1.67-1.01,3.65-1.51,5.94-1.51c1.99,0,3.72,0.42,5.19,1.27c1.47,0.84,2.61,2.03,3.4,3.55
									c0.79,1.52,1.19,3.33,1.19,5.41v16.74h-7.65V151.44z"/>
								<path d="M230.96,150.55c-0.03-1.22-0.29-2.28-0.77-3.15c-0.48-0.88-1.17-1.56-2.06-2.06
									c-0.89-0.5-1.92-0.75-3.08-0.75c-1.29,0-2.42,0.33-3.38,0.99c-0.96,0.66-1.71,1.57-2.24,2.73c-0.53,1.16-0.79,2.5-0.79,4.02
									c0,1.72,0.3,3.2,0.89,4.42c0.59,1.23,1.44,2.17,2.53,2.83c1.09,0.66,2.35,0.99,3.77,0.99c2.58,0,4.83-0.96,6.76-2.88l4.02,3.97
									c-1.32,1.49-2.95,2.63-4.89,3.43c-1.94,0.79-4.12,1.19-6.53,1.19c-2.85,0-5.32-0.56-7.4-1.69c-2.09-1.13-3.7-2.71-4.84-4.74
									c-1.14-2.04-1.71-4.4-1.71-7.08c0-2.75,0.58-5.14,1.74-7.18c1.16-2.04,2.77-3.62,4.84-4.74s4.46-1.71,7.18-1.74
									c3.15,0,5.72,0.65,7.72,1.96c2,1.31,3.46,3.15,4.37,5.51c0.91,2.37,1.25,5.17,1.02,8.42h-20.56v-4.47H230.96z"/>
							</g>
							{baseline && <g>
								<g>
									<path d="M47.52,184.75c-0.23-0.29-0.51-0.51-0.84-0.67c-0.33-0.16-0.65-0.24-0.98-0.24c-0.3,0-0.58,0.06-0.83,0.17
										c-0.26,0.11-0.48,0.27-0.68,0.47c-0.2,0.2-0.35,0.44-0.45,0.71c-0.11,0.27-0.16,0.57-0.16,0.89c0,0.31,0.05,0.61,0.16,0.88
										c0.11,0.27,0.26,0.51,0.45,0.71c0.2,0.2,0.42,0.36,0.68,0.48c0.26,0.12,0.53,0.17,0.83,0.17c0.32,0,0.64-0.07,0.96-0.22
										c0.32-0.15,0.61-0.35,0.86-0.6l0.93,0.97c-0.25,0.25-0.52,0.47-0.83,0.66c-0.31,0.19-0.63,0.33-0.97,0.43s-0.68,0.15-1.02,0.15
										c-0.53,0-1.01-0.09-1.45-0.27c-0.44-0.18-0.83-0.44-1.16-0.77c-0.33-0.33-0.59-0.71-0.77-1.15c-0.19-0.44-0.28-0.91-0.28-1.43
										c0-0.51,0.09-0.98,0.28-1.41c0.19-0.43,0.45-0.81,0.79-1.14c0.34-0.32,0.73-0.58,1.18-0.76c0.45-0.18,0.94-0.27,1.47-0.27
										c0.34,0,0.68,0.05,1.01,0.14c0.33,0.09,0.65,0.22,0.95,0.39c0.3,0.17,0.56,0.38,0.79,0.62L47.52,184.75z"/>
									<path d="M52.42,182.61c0.91,0,1.62,0.21,2.12,0.63c0.5,0.42,0.75,1.02,0.75,1.78c0,0.81-0.25,1.43-0.75,1.88
										c-0.5,0.45-1.21,0.67-2.12,0.67h-1.46v2.02h-1.58v-6.99H52.42z M52.42,186.3c0.43,0,0.77-0.1,1.01-0.3
										c0.24-0.2,0.36-0.51,0.36-0.92c0-0.4-0.12-0.7-0.36-0.9c-0.24-0.2-0.58-0.3-1.01-0.3h-1.46v2.43H52.42z M52.23,186.99h1.54
										l1.7,2.6h-1.79L52.23,186.99z"/>
									<path d="M56.61,182.61h5.32v1.27h-3.74v1.58h3.36v1.27h-3.36v1.61h3.85v1.27h-5.43V182.61z M59.67,180.47l1.36,0.53
										l-1.36,1.08l-1.12-0.1L59.67,180.47z"/>
									<path d="M65.52,182.61h1.62l2.97,6.99h-1.68l-2.15-5.41l-2.17,5.41h-1.64L65.52,182.61z M64.17,186.97h4.12v1.23
										h-4.12V186.97z"/>
									<path d="M69.43,182.61h5.84v1.3h-2.14v5.69h-1.58v-5.69h-2.13V182.61z"/>
									<path d="M76.12,182.61h5.32v1.27h-3.74v1.58h3.36v1.27h-3.36v1.61h3.85v1.27h-5.43V182.61z"/>
									<path d="M84.24,186.72c0,0.51,0.14,0.92,0.43,1.21c0.29,0.3,0.66,0.44,1.13,0.44c0.45,0,0.82-0.15,1.09-0.44
										c0.28-0.3,0.41-0.7,0.41-1.21v-4.11h1.58v4.11c0,0.61-0.12,1.13-0.37,1.58c-0.25,0.45-0.61,0.8-1.07,1.04
										c-0.46,0.24-1.01,0.36-1.65,0.36c-0.64,0-1.19-0.12-1.66-0.36c-0.47-0.24-0.83-0.59-1.08-1.04c-0.25-0.45-0.38-0.98-0.38-1.58
										v-4.11h1.58V186.72z"/>
									<path d="M93.33,182.61c0.91,0,1.62,0.21,2.12,0.63c0.5,0.42,0.75,1.02,0.75,1.78c0,0.81-0.25,1.43-0.75,1.88
										c-0.5,0.45-1.21,0.67-2.12,0.67h-1.46v2.02H90.3v-6.99H93.33z M93.33,186.3c0.43,0,0.77-0.1,1.01-0.3
										c0.24-0.2,0.36-0.51,0.36-0.92c0-0.4-0.12-0.7-0.36-0.9c-0.24-0.2-0.58-0.3-1.01-0.3h-1.46v2.43H93.33z M93.14,186.99h1.54
										l1.7,2.6h-1.79L93.14,186.99z"/>
									<path d="M103.17,182.61c0.54,0,1.04,0.08,1.49,0.25c0.45,0.17,0.84,0.41,1.16,0.72c0.33,0.31,0.58,0.68,0.76,1.1
										c0.18,0.43,0.27,0.9,0.27,1.42c0,0.51-0.09,0.98-0.27,1.41c-0.18,0.43-0.44,0.8-0.77,1.11c-0.33,0.31-0.73,0.55-1.18,0.72
										c-0.46,0.17-0.96,0.25-1.51,0.25h-2.9v-6.99H103.17z M103.21,188.31c0.3,0,0.57-0.05,0.82-0.16c0.25-0.11,0.46-0.26,0.64-0.45
										c0.18-0.2,0.32-0.43,0.42-0.69s0.15-0.56,0.15-0.89c0-0.33-0.05-0.62-0.16-0.89c-0.11-0.27-0.25-0.5-0.44-0.71
										c-0.19-0.2-0.41-0.36-0.66-0.46c-0.26-0.11-0.54-0.16-0.84-0.16h-1.35v4.42H103.21z"/>
									<path d="M107.9,182.61h5.32v1.27h-3.74v1.58h3.36v1.27h-3.36v1.61h3.85v1.27h-5.43V182.61z"/>
									<path d="M117.26,182.61h1.76l2.18,4.35l2.15-4.35h1.75v6.99h-1.42v-4.81l-1.99,4.1h-1.02l-2-4.1v4.81h-1.42V182.61z
										"/>
									<path d="M129.89,182.51c0.53,0,1.03,0.09,1.48,0.27c0.46,0.18,0.85,0.43,1.19,0.76c0.34,0.33,0.6,0.71,0.79,1.14
										c0.19,0.43,0.28,0.9,0.28,1.42c0,0.51-0.1,0.98-0.28,1.42c-0.19,0.44-0.45,0.82-0.79,1.15c-0.34,0.33-0.74,0.58-1.19,0.77
										s-0.95,0.27-1.48,0.27c-0.53,0-1.03-0.09-1.48-0.27c-0.46-0.18-0.85-0.44-1.19-0.77c-0.34-0.33-0.6-0.71-0.79-1.15
										c-0.19-0.44-0.28-0.91-0.28-1.42c0-0.51,0.09-0.99,0.28-1.42c0.19-0.44,0.45-0.81,0.79-1.14c0.34-0.32,0.74-0.58,1.19-0.76
										C128.87,182.6,129.36,182.51,129.89,182.51z M129.91,183.84c-0.29,0-0.57,0.06-0.83,0.17c-0.26,0.12-0.49,0.27-0.69,0.48
										c-0.2,0.21-0.35,0.45-0.46,0.72c-0.11,0.27-0.17,0.57-0.17,0.89c0,0.32,0.06,0.62,0.17,0.89c0.11,0.27,0.27,0.52,0.47,0.73
										c0.2,0.21,0.43,0.37,0.69,0.49c0.26,0.12,0.54,0.18,0.83,0.18c0.29,0,0.57-0.06,0.82-0.18c0.26-0.12,0.48-0.28,0.67-0.49
										c0.19-0.21,0.34-0.45,0.45-0.73c0.11-0.28,0.17-0.58,0.17-0.89c0-0.32-0.05-0.62-0.17-0.89c-0.11-0.27-0.26-0.51-0.45-0.72
										c-0.19-0.21-0.42-0.36-0.67-0.48C130.48,183.89,130.2,183.84,129.91,183.84z"/>
									<path d="M134.68,182.61h1.76l2.18,4.35l2.15-4.35h1.75v6.99h-1.42v-4.81l-1.99,4.1h-1.02l-2-4.1v4.81h-1.42V182.61z
										"/>
									<path d="M144.06,182.61h5.32v1.27h-3.74v1.58H149v1.27h-3.36v1.61h3.85v1.27h-5.43V182.61z"/>
									<path d="M150.73,182.61h1.44l3.8,5.13l-0.41,0.14v-5.27h1.49v6.99h-1.43l-3.8-5.12l0.42-0.14v5.26h-1.51V182.61z"/>
									<path d="M157.91,182.61h5.84v1.3h-2.14v5.69h-1.58v-5.69h-2.13V182.61z"/>
									<path d="M169.19,184.51c-0.25-0.15-0.5-0.28-0.76-0.38c-0.26-0.1-0.52-0.18-0.76-0.24
										c-0.24-0.06-0.46-0.09-0.65-0.09c-0.27,0-0.48,0.05-0.64,0.16c-0.16,0.1-0.24,0.26-0.24,0.46c0,0.19,0.07,0.35,0.21,0.47
										c0.14,0.12,0.31,0.22,0.53,0.3s0.46,0.15,0.73,0.23c0.27,0.08,0.53,0.16,0.79,0.26c0.26,0.1,0.51,0.23,0.73,0.38
										c0.22,0.16,0.4,0.36,0.53,0.6s0.19,0.54,0.19,0.9c0,0.45-0.12,0.84-0.36,1.15c-0.24,0.31-0.57,0.55-0.98,0.72
										c-0.42,0.17-0.88,0.25-1.39,0.25c-0.39,0-0.76-0.05-1.14-0.14c-0.37-0.09-0.72-0.22-1.05-0.39c-0.33-0.17-0.63-0.37-0.89-0.59
										l0.63-1.24c0.24,0.21,0.5,0.4,0.79,0.56c0.29,0.16,0.58,0.28,0.87,0.37c0.29,0.09,0.56,0.13,0.81,0.13
										c0.33,0,0.58-0.06,0.76-0.19c0.18-0.13,0.27-0.31,0.27-0.54c0-0.2-0.07-0.36-0.2-0.48c-0.13-0.12-0.31-0.22-0.53-0.3
										c-0.22-0.08-0.46-0.16-0.73-0.23c-0.27-0.07-0.53-0.15-0.8-0.25c-0.27-0.1-0.51-0.22-0.73-0.37c-0.22-0.15-0.4-0.34-0.53-0.58
										c-0.14-0.23-0.21-0.53-0.21-0.89c0-0.43,0.11-0.79,0.34-1.1c0.23-0.31,0.54-0.55,0.95-0.72c0.41-0.17,0.88-0.25,1.42-0.25
										c0.49,0,0.97,0.07,1.44,0.21c0.47,0.14,0.87,0.33,1.22,0.55L169.19,184.51z"/>
									<path d="M173.65,182.61h1.58v6.99h-1.58V182.61z"/>
									<path d="M176.77,182.61h1.44l3.8,5.13l-0.41,0.14v-5.27h1.49v6.99h-1.43l-3.8-5.12l0.42-0.14v5.26h-1.51V182.61z"/>
									<path d="M186.88,182.61h1.62l2.97,6.99h-1.68l-2.15-5.41l-2.17,5.41h-1.64L186.88,182.61z M185.53,186.97h4.12v1.23
										h-4.12V186.97z"/>
									<path d="M190.79,182.61h5.84v1.3h-2.14v5.69h-1.58v-5.69h-2.13V182.61z"/>
									<path d="M196.79,182.61h5.84v1.3h-2.14v5.69h-1.58v-5.69h-2.13V182.61z"/>
									<path d="M203.48,182.61h5.32v1.27h-3.74v1.58h3.36v1.27h-3.36v1.61h3.85v1.27h-5.43V182.61z"/>
									<path d="M210.14,182.61h1.44l3.8,5.13l-0.41,0.14v-5.27h1.49v6.99h-1.43l-3.8-5.12l0.42-0.14v5.26h-1.51V182.61z"/>
									<path d="M220.96,182.61c0.54,0,1.04,0.08,1.49,0.25c0.45,0.17,0.84,0.41,1.16,0.72c0.33,0.31,0.58,0.68,0.76,1.1
										c0.18,0.43,0.27,0.9,0.27,1.42c0,0.51-0.09,0.98-0.27,1.41c-0.18,0.43-0.44,0.8-0.77,1.11c-0.33,0.31-0.73,0.55-1.18,0.72
										c-0.46,0.17-0.96,0.25-1.51,0.25h-2.9v-6.99H220.96z M221,188.31c0.3,0,0.57-0.05,0.82-0.16c0.25-0.11,0.46-0.26,0.64-0.45
										c0.18-0.2,0.32-0.43,0.42-0.69s0.15-0.56,0.15-0.89c0-0.33-0.05-0.62-0.16-0.89c-0.11-0.27-0.25-0.5-0.44-0.71
										c-0.19-0.2-0.41-0.36-0.66-0.46c-0.26-0.11-0.54-0.16-0.84-0.16h-1.35v4.42H221z"/>
									<path d="M227.14,186.72c0,0.51,0.14,0.92,0.43,1.21s0.66,0.44,1.13,0.44c0.45,0,0.82-0.15,1.09-0.44
										c0.28-0.3,0.41-0.7,0.41-1.21v-4.11h1.58v4.11c0,0.61-0.12,1.13-0.37,1.58c-0.25,0.45-0.61,0.8-1.07,1.04
										c-0.46,0.24-1.01,0.36-1.65,0.36c-0.64,0-1.19-0.12-1.66-0.36c-0.47-0.24-0.83-0.59-1.08-1.04c-0.25-0.45-0.38-0.98-0.38-1.58
										v-4.11h1.58V186.72z"/>
									<path d="M237.8,184.51c-0.25-0.15-0.5-0.28-0.76-0.38s-0.52-0.18-0.76-0.24c-0.24-0.06-0.46-0.09-0.65-0.09
										c-0.27,0-0.48,0.05-0.64,0.16c-0.16,0.1-0.24,0.26-0.24,0.46c0,0.19,0.07,0.35,0.21,0.47c0.14,0.12,0.31,0.22,0.53,0.3
										s0.46,0.15,0.73,0.23c0.27,0.08,0.53,0.16,0.79,0.26c0.26,0.1,0.51,0.23,0.73,0.38c0.22,0.16,0.4,0.36,0.53,0.6
										c0.13,0.24,0.19,0.54,0.19,0.9c0,0.45-0.12,0.84-0.36,1.15c-0.24,0.31-0.57,0.55-0.98,0.72c-0.42,0.17-0.88,0.25-1.39,0.25
										c-0.39,0-0.76-0.05-1.14-0.14c-0.37-0.09-0.72-0.22-1.05-0.39c-0.33-0.17-0.63-0.37-0.89-0.59l0.63-1.24
										c0.24,0.21,0.5,0.4,0.79,0.56c0.29,0.16,0.58,0.28,0.87,0.37c0.29,0.09,0.56,0.13,0.81,0.13c0.33,0,0.58-0.06,0.76-0.19
										c0.18-0.13,0.27-0.31,0.27-0.54c0-0.2-0.07-0.36-0.2-0.48c-0.13-0.12-0.31-0.22-0.53-0.3c-0.22-0.08-0.46-0.16-0.73-0.23
										c-0.27-0.07-0.53-0.15-0.8-0.25c-0.27-0.1-0.51-0.22-0.73-0.37c-0.22-0.15-0.4-0.34-0.53-0.58c-0.14-0.23-0.21-0.53-0.21-0.89
										c0-0.43,0.11-0.79,0.34-1.1c0.23-0.31,0.54-0.55,0.95-0.72c0.41-0.17,0.88-0.25,1.42-0.25c0.49,0,0.97,0.07,1.44,0.21
										c0.47,0.14,0.87,0.33,1.22,0.55L237.8,184.51z"/>
								</g>
							</g>}
						</g>
						<g>
							<circle cx="139.62" cy="73.86" r="2.43"/>
							<path d="M140.75,82.75h-2.31c-0.66,0-1.17-0.59-1.07-1.24l0.93-6.11h2.63l0.92,6.08
								C141.96,82.14,141.44,82.75,140.75,82.75z"/>
						</g>
						<g>
							<polygon points="129.91,57.28 129.92,57.28 129.91,57.28 		"/>
							<path d="M148.21,57.28h-18.29c2.76,0,5.52,1.05,7.61,3.14l8.25,8.25c2.27,2.27,5.34,3.54,8.54,3.54h5.72V69.9
								C160.05,62.93,154.75,57.28,148.21,57.28z"/>
						</g>
						<path d="M156.91,79.8l-7.51-7.51v25.87c2.73-0.02,5.45-1.07,7.51-3.14C161.1,90.83,161.1,83.98,156.91,79.8z"/>
						<path d="M129.97,57.28c-2.78-0.01-5.56,1.03-7.67,3.14l0,0c-4.19,4.19-4.19,11.04,0,15.22l7.67,7.67V57.28z"/>
						<path d="M141.69,95.02l-8.56-8.56c-2.04-2.04-4.8-3.18-7.68-3.18h-6.28v2.31c0,6.97,5.32,12.58,11.85,12.58l18.22-0.01
							l0,0C146.5,98.14,143.76,97.1,141.69,95.02z"/>
					</g>
				</svg>
			</a>
		</Link>
	)
}
