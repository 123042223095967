
/**
 * Tarte au citron
 * @see https://tarteaucitron.io
 */

import Head from "next/head"

const TarteAuCitron = () => {

	if (!process.env.TARTEAUCITRON_CDN)
		return null

	return (
		<Head>
			<script
				type="text/javascript"
				src={process.env.TARTEAUCITRON_CDN}
			/>
			<script
				type="text/javascript"
				dangerouslySetInnerHTML={{
					__html: `tarteaucitron.init({
						"privacyUrl": "", /* Privacy policy url */
						"bodyPosition": "top", /* or top to bring it as first element for accessibility */
		
						"hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
						"cookieName": "tarteaucitron", /* Cookie name */
				
						"orientation": "bottom", /* Banner position (top - middle - bottom) */
					
						"groupServices": false, /* Group services by category */
						"showDetailsOnClick": true, /* Click to expand the description */
						"serviceDefaultState": "wait", /* Default state (true - wait - false) */
															
						"showAlertSmall": false, /* Show the small banner on bottom right */
						"cookieslist": false, /* Show the cookie list */
															
						"closePopup": false, /* Show a close X on the banner */

						"showIcon": true, /* Show cookie icon to manage cookies */
						//"iconSrc": "", /* Optionnal: URL or base64 encoded image */
						"iconPosition": "BottomRight", /* BottomRight, BottomLeft, TopRight and TopLeft */
		
						"adblocker": false, /* Show a Warning if an adblocker is detected */
															
						"DenyAllCta" : true, /* Show the deny all button */
						"AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
						"highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */
															
						"handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */
		
						"removeCredit": true, /* Remove credit link */
						"moreInfoLink": true, /* Show more info link */
		
						"useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */
						"useExternalJs": false, /* If false, the tarteaucitron.js file will be loaded */
		
						//"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */
															
						"readmoreLink": "", /* Change the default readmore link */

						"mandatory": true, /* Show a message about mandatory cookies */
						"mandatoryCta": true, /* Show the disabled accept button when mandatory on */
			
						//"customCloserId": "" /* Optional a11y: Custom element ID used to open the panel */
					});`
				}}
			/>
		</Head>
	)
}

/**
 * Google Tag
 *
 * @need {GTAG_ID} environment var
 */
export const TarteAuCitronGA4 = () => {

	if (!process.env.TARTEAUCITRON_CDN || !process.env.GTAG_ID)
		return null

	return (
		<script
			type="text/javascript"
			dangerouslySetInnerHTML={{
				__html: `tarteaucitron.user.gtagUa = '${process.env.GTAG_ID}';
        // tarteaucitron.user.gtagCrossdomain = ['example.com', 'example2.com'];
        tarteaucitron.user.gtagMore = function () { /* add here your optionnal gtag() */ };
        (tarteaucitron.job = tarteaucitron.job || []).push('gtag');`
			}}
		/>
	)
}

/**
 * Facebook Pixel
 *
 * @need {FACEBOOK_PIXEL_ID} environment var
 */
export const TarteAuCitronFbPixel = () => {

	if (!process.env.TARTEAUCITRON_CDN || !process.env.FACEBOOK_PIXEL_ID)
		return null

	return (
		<script
			type="text/javascript"
			dangerouslySetInnerHTML={{
				__html: `tarteaucitron.user.facebookpixelId = '${process.env.FACEBOOK_PIXEL_ID}'; tarteaucitron.user.facebookpixelMore = function () { /* add here your optionnal facebook pixel function */ };
        (tarteaucitron.job = tarteaucitron.job || []).push('facebookpixel');`
			}}
		/>
	)
}

export default TarteAuCitron
