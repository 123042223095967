
import { SITE_NAME, SITE_URL, TWITTER_SITE, TEXT_SEPARATOR, DEFAULT_HERO_IMAGE } from 'libs/variables'

export const defaultSeo = {
	titleTemplate: `%s${TEXT_SEPARATOR}${SITE_NAME}`,
	title: SITE_NAME,
	openGraph: {
		type: 'website',
		locale: 'fr_FR',
		url: SITE_URL,
		site_name: SITE_NAME,
		images: [
			{
				url: `${SITE_URL}${DEFAULT_HERO_IMAGE}`,
				width: 1600,
				height: 1080
			}
		]
	},
	twitter: {
		site: `@${TWITTER_SITE}`,
		cardType: 'summary_large_image',
	},
	dangerouslySetAllPagesToNoIndex: process.env.APP_ENV !== 'prod',
	dangerouslySetAllPagesToNoFollow: process.env.APP_ENV !== 'prod'
}
