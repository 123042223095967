
import React from 'react'
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

import Logo from 'components/logo'
import Title from 'components/title'
import { Content, Divider } from 'components/generic'
import Button from 'components/button'
import Section from 'components/section'
import Wrapper from 'components/wrapper'
import Layout, { LayoutItem } from 'components/layout'
import { Navbar, Menu } from 'components/navigation'
import { Icon } from 'components/media'
import NewsletterForm from 'components/newsletter'

import { scrollToTop } from '../libs/navigation'

export default function () {
	return (
		<footer className="o-colophon">
			<Section className="u-padding-bottom u-position-relative">

				<Wrapper>

					<section className="u-position-relative u-flex-align--center u-color--white u-background-color--primary u-min-height--full u-rounded">

						<Wrapper className="u-position-relative">

							<Layout isFlex vAlign="middle">

								<LayoutItem
									widths={ {
										'tablet': '1/2',
										'tablet-cart': '1/1'
									} }
									className="u-flex-direction--column u-flex-justify--space-between u-margin-top u-margin-bottom"
								>

									<Content className="u-margin-bottom-normal">
										<Title tag="h4" size="large">Suivez les actualités<br/>de Sibylline Escapade</Title>

										<NewsletterForm/>
									</Content>

									<Layout
										isAuto
										size="small"
										vAlign="middle"
										className="u-margin-bottom-large"
									>
										<LayoutItem>
											<a href="https://www.facebook.com/sibyllineescapade/" target="_blank">
												<Icon size="medium" fa={ faFacebook }/>
											</a>
										</LayoutItem>
										<LayoutItem>
											<a href="https://twitter.com/sibyllinescapad" target="_blank">
												<Icon size="medium" fa={ faTwitter }/>
											</a>
										</LayoutItem>
										<LayoutItem>
											<a href="https://www.instagram.com/sibyllineescapade/" target="_blank">
												<Icon size="medium" fa={ faInstagram }/>
											</a>
										</LayoutItem>
									</Layout>

									<Layout isFlex size="normal" className="u-flex-align--center">
										<LayoutItem
											widths={ {
												'mobile': '2/5'
											} }
										>
											<Logo />
										</LayoutItem>
										<LayoutItem
											widths={ {
												null: '1/1',
												'mobile': '3/5'
											} }
											className="u-margin-top-normal u-margin-top-none@mobile"
										>
											<Content isSummary>
												<p className="u-font-size--medium u-margin-none"><strong>02 44 88 93 30</strong></p>
												<p className="u-margin-bottom-tiny"><strong>contact@sibylline-escapade.fr</strong></p>
												<p className="u-font-size--tiny u-text-transform--uppercase">8 avenue du Président Coty<br/>
												49240 Avrillé</p>
											</Content>
										</LayoutItem>
									</Layout>

								</LayoutItem>

								<LayoutItem
									className="u-hidden@tablet"
								>
									<Divider color="white"/>
								</LayoutItem>

								<LayoutItem
									widths={ {
										'tablet': '1/2',
										'tablet-cart': '1/1'
									} }
									className="u-margin-top u-margin-bottom"
								>

									<Layout isFlex className="u-flex-grow">
										<LayoutItem
											widths={ {
												'mobile': '1/2'
											} }
											className="u-margin-vertical-normal"
										>
											<Menu
												type="footer"
												items={ [
													{
														title: 'Séjours surprises',
														href: "/[taxon]",
														as: "/sejours-surprises",
														subItems: [
															{
																title: 'Comment ça marche ?',
																href: "/[taxon]#decouvrir",
																as: '/sejours-surprises#decouvrir'
															},
															{
																title: 'Préparez votre escapade',
																href: '/formulaire/sejour-surprise'
															},
														]
													},
												] }
											/>

											<Menu
												type="footer"
												items={ [
													{
														title: 'Cadeaux',
														href: '/[taxon]',
														as: '/cadeaux',
														subItems: [
															{
																title: 'Sibylline Pass',
																href: '/[taxon]/[child]',
																as: '/cadeaux/sibylline-pass',
																subItems: [
																	{
																		title: 'Offrir',
																		href: '/[taxon]/[child]#offrir',
																		as: '/cadeaux/sibylline-pass#offrir',
																	},
																	{
																		title: 'Utiliser',
																		href: '/[taxon]/[child]#utiliser',
																		as: '/cadeaux/sibylline-pass#utiliser',
																	},
																]
															},
															{
																title: 'Sibylline Tickets',
																href: '/[taxon]/[child]',
																as: '/cadeaux/sibylline-ticket',
																subItems: [
																	{
																		title: 'Offrir',
																		href: '/[taxon]/[child]#offrir',
																		as: '/cadeaux/sibylline-ticket#offrir',
																	},
																	{
																		title: 'Utiliser',
																		href: '/[taxon]/[child]#utiliser',
																		as: '/cadeaux/sibylline-ticket#utiliser',
																	},
																]
															},
														]
													},
												] }
											/>
										</LayoutItem>
										<LayoutItem
											widths={ {
												'mobile': '1/2'
											} }
											className="u-margin-vertical-normal"
										>
											<Menu
												type="footer"
												items={ [
													{
														title: 'Événements',
														href: '/[taxon]',
														as: '/evenements',
														subItems: [
															{
																title: 'Tous les événements',
																href: '/[taxon]',
																as: '/evenements'
															},
														]
													},
												] }
											/>

											<Menu
												type="footer"
												items={ [
													{
														title: 'Sibylline Escapade',
														href: '/',
														subItems: [
															{
																title: 'Qui sommes nous ?',
																href: '/qui-sommes-nous'
															},
															{
																title: 'Agence Événementielle',
																href: '/agence-evenementielle'
															},
															{
																title: 'Ils parlent de nous',
																href: '/ils-parlent-de-nous'
															},
															{
																title: 'FAQ',
																href: '/faq'
															},
															{
																title: 'Conditions générales de vente',
																href: '/cgv',
															},
															{
																title: 'Contact',
																href: '/contact'
															}
														]
													},
												] }
											/>
										</LayoutItem>
									</Layout>

								</LayoutItem>

							</Layout>

						</Wrapper>

					</section>{ /* o-box */ }

				</Wrapper>

				<Navbar
					absolute="bottom"
					background="white"
					zIndex={ 99 }
				>
					<Menu
						hasLayout
						widths="1/3"
					>
					</Menu>
					<Menu
						hasLayout
						widths="1/3"
						align="center"
					>
						<Icon
							name="chevron-up"
							size="large"
							color="light-gray"
							onClick={ scrollToTop }
						/>
					</Menu>
					<Menu
						hasLayout
						type="legal"
						widths="1/3"
						align="end"
						color="gray"
						items={ [
							{
								title: 'Mentions légales',
								href: '/mentions-legales'
							}
						] }
					>
					</Menu>
				</Navbar>

			</Section>
		</footer>
	)
}
