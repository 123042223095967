import React from "react";
import App from "next/app";
import Head from "next/head";
import { Provider as ProvideAlert } from "react-alert";
import { DefaultSeo } from "next-seo";

import Layout from "layouts";

import { AlertTemplate, alertOptions } from "components/alert";
import TarteAuCitron, { TarteAuCitronGA4, TarteAuCitronFbPixel } from "components/tarteaucitron";

import { ProvideMenu } from "contexts/MenuContext";
import { ProvideModal } from "contexts/ModalContext";
import { ProvideUser, getUserData } from "contexts/UserContext";
import { ProvideCart, getCartData } from "contexts/CartContext";
import { ProvideCheckout, getCheckoutData } from "contexts/CheckoutContext";

import { isServerSide } from "libs/utils";
import { defaultSeo } from "libs/seo";

import * as Sentry from "@sentry/browser";
import { provideGeneralData } from "libs/_sentry";

import 'react-dates/initialize';

import "styles/main.sass";

export default class MyApp extends App {
	constructor(props) {
		super(props);
		// Force https
		// TODO: make this less ugly
		if (!isServerSide() && process.env.APP_ENV == 'prod') {
			if ( window.location.protocol === "http:" ) {
				window.location = `https://${window.location.host}${window.location.pathname}${window.location.search}${window.location.hash}`;
			}
		}
	}

	componentDidCatch(error, errorInfo) {
		// Sentry setup
		if (process.env.SENTRY_ON == "true") {
			Sentry.withScope((scope) => {
				Object.keys(errorInfo).forEach((key) => {
					scope.setExtra(key, errorInfo[key]);
				});
			});

			Sentry.captureException(error);

			super.componentDidCatch(error, errorInfo);
		} else {
			console.log("New error", error, errorInfo);
		}
	}

	render() {
		const { Component, appProps, data } = this.props;
		const { user, cart, checkout } = data;

		provideGeneralData(data);

		return (
			<>
				<DefaultSeo { ...defaultSeo } />
				<TarteAuCitron />
				<Head>
					<meta name="facebook-domain-verification" content="pi10ikafx02wq1nxs1406dty78z8tc" />
					<link rel="icon" href="/icon-32x32.jpg" sizes="32x32" />
					<link rel="icon" href="/icon-192x192.jpg" sizes="192x192" />
					<link
						rel="apple-touch-icon-precomposed"
						href="/apple-touch-icon-precomposed.jpg"
					/>
					<meta
						name="msapplication-TileImage"
						content="/msapplication-TileImage.jpg"
					/>
				</Head>
				<ProvideAlert template={ AlertTemplate } options={ alertOptions }>
					<ProvideMenu>
						<ProvideModal>
							<ProvideUser data={ user }>
								<ProvideCart data={ cart }>
									<ProvideCheckout data={ checkout }>
										<Layout>
											<Component { ...appProps.pageProps } />
										</Layout>
									</ProvideCheckout>
								</ProvideCart>
							</ProvideUser>
						</ProvideModal>
					</ProvideMenu>
				</ProvideAlert>
				<TarteAuCitronGA4 />
				<TarteAuCitronFbPixel />
			</>
		);
	}
}

MyApp.getInitialProps = async (appContext) => {
	const appProps = await App.getInitialProps(appContext);

	const user = await getUserData(appContext);
	const cart = await getCartData(appContext);
	const checkout = await getCheckoutData(appContext);

	return {
		data: {
			user,
			cart,
			checkout,
		},
		appProps,
	};
};
