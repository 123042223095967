
import classNames from 'classnames'

import Button, { Buttons } from 'components/button'
import Card, { CardContent, CardFooter } from 'components/card'

export const alertOptions = {
	containerStyle: {
		zIndex: 1000,
		pointerEvents: 'all'
	}
}

export function AlertTemplate( props ) {

	const {
		style,
		options,
		message,
		close
	} = props

	return (
		<div
			className={ classNames(
				'o-modal o-modal--active'
			) }
		>
			<div
				className={ classNames(
					'o-modal__overlay'
				) }
			/>
			<div
				className="o-modal__container"
			>
				<Card
					hasBorder={ false }
					widths={ {
						mobile: '2/3',
						tablet: '1/2',
						desktop: '1/3',
						wide: '1/4'
					} }
					theme={ options.type }
					style={ style }
					className={ options.type !== 'error' ? 'u-box-shadow' : null }
				>
					<CardContent
						size="medium"
					>
						{ message }
					</CardContent>
					<CardFooter
						size="medium"
						className="u-text-align--right"
					>
						<Button
							size="small"
							onClick={ close }
						>
							OK
						</Button>
					</CardFooter>
				</Card>
			</div>
		</div>
	)
}
