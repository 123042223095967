
import React, { forwardRef } from 'react'

const Page = forwardRef( (props, ref) => {

	const {
		children
	} = props

	return (
		<div className="o-page" ref={ ref }>
			<div className="o-page__container">
				{ children }
			</div>
		</div>
	)
} )

export default Page
